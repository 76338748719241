import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTranferService {
  private messageSource = new BehaviorSubject<any>(null);
  currentPhone = this.messageSource.asObservable();
  private changeFields = new BehaviorSubject<any>(null);
  currentFields = this.messageSource.asObservable();
  constructor() { }

  changePhoneNumber(message: string) {
    // console.log(message);
    this.messageSource.next(message);
  }
  changePhoneFields(message: boolean) {
  
    this.changeFields.next(message);
  }
}
