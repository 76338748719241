import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { DataTranferService } from '../../services/data-tranfer.service';


@Component({
  selector: 'app-edit-phone',
  templateUrl: './edit-phone.component.html',
  styleUrls: ['./edit-phone.component.scss'],
})
export class EditPhoneComponent {


  @Output() showButton = new EventEmitter<any>();
  invalidNo = false;
  inphone: any;
  isValidPhoneNumber: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneForm: any = new FormGroup({
    phone: new FormControl(undefined, [Validators.required, Validators.minLength(5)]),
  });
 
  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  constructor(
    private phoneField:DataTranferService,
    private route: Router,
  
  ) {}
  ngOnInit(): void {
    // this.phoneForm.patchValue({
    //   phone: ,
    // });

    this.phoneField.currentPhone.subscribe((message) => {
      if(message){
        console.log("phone component: "+message);
        this.phoneForm.patchValue({
          phone: message.substring(4).trim(),
        });
      }
      
    });
  }
 
  
  async onChangeCode(event :any){
    // const temp = this.phoneForm.controls.value;
    // // const phoneUser =temp.internationalNumber.toString().substring(4).trim();
    // console.log(this.phoneForm.controls.phone.pendingValue,this.phoneForm.controls.phone)
    
    this.phoneField.changePhoneFields(true);
    this.phoneField.currentFields.subscribe((message) => {
      console.log("phone component: "+message);
      
    });
    if (this.phoneForm.invalid) {
      this.invalidNo = true;
    } else {
      this.invalidNo = false;
      this.onInput(null);
    

    }
    this.showButton.emit(true);
 
    
  }
  onFocus() {
    this.phoneField.changePhoneFields(true);
    this.showButton.emit(true);
  }
  onBlur() {
    this.phoneField.changePhoneFields(true);
    this.showButton.emit(true);
    }
  onInput(event :any) {
  
    if (this.phoneForm.invalid) {
      this.invalidNo = true;
    } else {
      this.invalidNo = false;
      let phone: any = this.phoneForm.value;
      this.inphone = phone?.phone?.internationalNumber;
     
      this.phoneField.changePhoneNumber(this.inphone);
    

    }
 
  }
  
  
}
