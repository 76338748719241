import { Inject, Injectable } from '@angular/core';
import { HttpHelperService } from '../../../core/services/common/http-helper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../../core/services/common/profile.service';
import { AuditUserLogsService } from 'src/app/core/services/common/audit-user-logs.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root',
})
export class MyaccountService {
  public emailId :string;
  public bodyParams: any;
  public userProfileData = new BehaviorSubject<any>({});
  private API_URL = environment.reviewRequestUrl.approvalUrl;
  constructor(
    private httpHelperService: HttpHelperService,
    private profileService: ProfileService,
    private http: HttpClient,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private audit : AuditUserLogsService
  ) { this.profileService.UserProfile.subscribe((data: any) => {
    this.emailId = data.email_id;
   
  });}
  /**
   * Updates the user profile.
   *
   * @param params - The parameters for updating the profile.
   */
  async addHeader() {
    let authConfig;
   
   
    const accessToken = this.oktaAuth.getAccessToken();
    const getheaders = new HttpHeaders({
      'Content-Type': `application/json`,
      'Access-Control-Allow-Origin': `*`, 
      Authorization: `Bearer ${accessToken}`,
      Accept: `application/json; okta-version=1.0.0`,
    });
    return getheaders;
  }
  async approvalHeader(){
    const accessToken = this.oktaAuth.getAccessToken();
    const getheaders = new HttpHeaders({
      'Content-Type': `application/json`,
      'Access-Control-Allow-Origin': `*`,      
      'client_secret':environment.reviewRequestUrl.adiClientSecrete,
      'client_id':environment.reviewRequestUrl.adiClientId,
      Authorization: `Bearer ${accessToken}`,
      
    });
    return getheaders;
  }
  public async updateUserProfile(params: any) {
    // Send a PATCH request to the protected resource with the provided parameters
    
   
  }
  public async getUserProfile() {
    // Send a PATCH request to the protected resource with the provided parameters
   
    const response =
      await this.httpHelperService.getUserProfileData();

    // Subscribe to the response observable
    response.subscribe({
      // When the response is received
      next: (res: any) => {
        // Load the updated user profile
      //  this.profileService.loadUserProfile();
      this.userProfileData.next(res);
      },
      // When an error occurs
      error: (err) => console.error('An error occurred:', err),
      // When the request is completed
      complete: () => {       
       
          return true;
       // }
      },
    });
  }
  async reviewRequest(bodyParam: {
    ticketNumber: string;
    countryCode: string;
    email: string;
    status: string;
  }): Promise<Observable<any>> {
    const headers = await this.addHeader();
    return this.http.post(this.API_URL, JSON.stringify(bodyParam),{
      headers,
      observe: 'response',
    });
  }
  async requestorData(url: string) {
   
    const headers = await this.approvalHeader();
    const response = await this.http.get(
      environment.reviewRequestUrl.requestorDetailsUrl+url,
      {
        headers       
      }
    );
    return response;
  }
}
