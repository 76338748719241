<div class="px-3 py-5 privacy-card">
  <div class="fw-light fs-1 pt-4">
    <!-- <div class="back-link">&lt;<a href="https://www.myalcon.com/">Back to [origin/close window]</a></div> -->
    <p class="head-title">{{ "mnumyprivacyandsecuritySettings" | translate }}</p>
  </div>
  <div class="fw-bold fs-3 py-2 sub-header">Account verification</div>
  <div class="px-3 py-3">
    <div *ngIf="isPhoneFactorErrorMsg" class="error-text alert alert-danger">                      
      {{isPhoneFactorErrorMsg }}     </div>

    <div *ngIf="isSecretQuestionFactorErrorMsg" class="error-text alert alert-danger">                      
      {{isSecretQuestionFactorErrorMsg}} </div>

    <div class="row card-group py-2">
      <div class="col- col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card rounded-left rounded-right">
          <div class="card-body text-left">
            <h5 class="card-title alcon-text-color text pt-4 fw-bold">{{'VerificationPhoneNumber' | translate}} <span><i class="bi bi-check-circle"></i></span></h5>
            <div class="card-body">
              <div class="">
                <p class="card-text px-3 lead sub-text" > +0X1 22XX 2X XX1</p>
              </div>
              <div class="px-3">
                <button type="button" class="btn btn-submit py-1 float-end" (click)="disablePhoneFactorComponent()"  *ngIf="enablePhoneFactorDisableButton" ><i class="bi bi-x-lg"></i>
                  <span> {{'btndisable'| translate}}</span>
                </button>
                <button type="button" class="btn btn-edit py-1 end-float" *ngIf="!isPhoneFactorEditMode && !enablePhoneFactorDisableButton" (click)="loadPhoneFactorComponent()"><i
                    class="bi bi-power"></i>
                  <span> {{'btnenable'| translate}}</span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <router-outlet name="phonefactor" *ngIf="isPhoneFactorEditMode"></router-outlet>
            </div>
            <hr />
            <h5 class="card-title alcon-text-color pt-4 fw-bold text">{{'VerificationSecurityQuestion' | translate}}</h5>
            <div class="card-body text-left">
              <div>
                <p class="card-text px-3 lead sub-text">{{'selectanoption' | translate}}
                </p>
              </div>
              <div class="px-3">
                <button type="button" class="btn btn-submit py-1 float-end"  (click)="disableSecretFactorComponent()" *ngIf="enableSecretQuestionDisablesButton"  ><i class="bi bi-x-lg"></i>
                  <span> {{'btndisable'| translate}}</span>
                </button>
                <button type="button" class="btn btn-edit py-1 end-float" *ngIf="!isSecretQuestionFactorEditMode && !enableSecretQuestionDisablesButton" (click)="loadSecretFactorComponent()"><i
                    class="bi bi-power"></i>
                  <span> {{'btnenable'| translate}}</span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <router-outlet name="seceretfactors" *ngIf="isSecretQuestionFactorEditMode" ></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fw-bold fs-3 py-2 sub-header">Alcon notices</div>
  <div class="px-3 py-3">
    <div class="row card-group py-2">
      <div class="col- col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card rounded-left rounded-right">
          <div class="card-body text-left">
            <h5 class="card-title alcon-text-color text pt-4 fw-bold">Privacy Notices<span></span></h5>
            <div class="card-body">
              <div class="">
                <p class="card-text lead sub-text" > Privacy Notices</p>
              </div>
              <div class="px-3">
               
                <button type="button" class="btn btn-edit py-1 float-end view-btn " >
                  <i class="bi bi-eye"></i>
                  <!-- <span><a href="https://preferences.myalcon.com/privacy-notice/?hidebanner=true" target="_blank">View</a> </span> -->
                  <span><a href="https://preferences.myalcon.com/privacy-notice/?hidebanner=true" target="_blank"> View</a> </span>
                </button>
              </div>
            </div>
            <div class="card-body">
            </div>
            <hr />
            <h5 class="card-title alcon-text-color pt-4 fw-bold text">Terms of Use</h5>
            <div class="card-body text-left">
              <div class="">
                <p class="card-text lead sub-text">Terms of Use
                </p>
              </div>
              <div class="px-3">
                <button type="button" class="btn btn-edit py-1 float-end view-btn" ><i
                  class="bi bi-eye"></i>
                <span><a href="https://preferences.myalcon.com/terms-of-use-us/?hidebanner=true" target="_blank"> View</a> </span>
              </button>
               
              </div>
            </div>
            <div class="card-body">
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>