import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpHelperService } from './http-helper.service';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuditUserLogsService } from './audit-user-logs.service';

interface Claim {
  claim: string;
  value: any;
}
@Injectable({
  providedIn: 'root',
})
// This servie provides you the profile rlated information
export class ProfileService {
  public UserProfile = new BehaviorSubject<any>({});
  public resetPasswordResponse = new BehaviorSubject<any>({});
  claims: Claim[] = [];
  public records: any = null;
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private httpClient: HttpClient,
    private httpHelperService: HttpHelperService,
    private router: Router,
    private audit : AuditUserLogsService
  ) {}

  // change the api url try to make it all the url taken from the configurations.
  public async loadUserProfile(): Promise<any> {
    try {
      const userClaims = await this.oktaAuth.getUser();
      this.claims = Object.entries(userClaims).map((entry) => ({
        claim: entry[0],
        value: (entry[1] === undefined || entry[1] == 'undefined') ? '' :  entry[1] ,
      }));

      this.UserProfile.next(userClaims);
     
      return userClaims;
    } catch (error:any) {
      localStorage.removeItem('okta-cache-storage');
      localStorage.removeItem('okta-token-storage');
      this.oktaAuth.clearStorage();
      this.oktaAuth.closeSession()
      window.location.href = '/';
      }
  }

  public async resetPassWord(newPasspwrd: string) {
    const parms = {
      profile: {
        password: newPasspwrd,
      },
    };
    (
      await this.httpHelperService.putOktaCallWithBodyParams(
        environment.apilist.userapis.resetpassword,
        parms
      )
    ).subscribe({
      next: (res: any) => {
        this.resetPasswordResponse.next(res);
        //this.MyEntitlesdApps.next(res);
      },
      error: (error: any) => {
        this.resetPasswordResponse.next(error);
      },
      complete: () => console.log('There are no more action happen.'),
    });
  }

  public async universallogut() {
  }



}
