import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { StepupService } from 'src/app/core/stepup/stepup.service';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { filter } from 'rxjs';
import { ConfrimModalComponent } from './confirmation.popup-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-myalcon-account',
  templateUrl: './myalcon-account.component.html',
  styleUrls: ['./myalcon-account.component.scss'],
})


export class MyalconAccountComponent implements AfterViewInit, OnInit {
  public user: any;
  public profile = this.profileService.UserProfile;

  public isProfileEditMode: boolean = false;
  public isPhoneEditMode: boolean = false;
  public isPasswordResetMode: boolean = false;

  userid = '';
  @ViewChild('myButton', { static: true }) myElementRef!: ElementRef;
  public IsEditProfileEnabled: boolean = environment.IsEditProfileEnabled;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private communicationService: CommunicationService,
    private stepupService: StepupService,
    private cdr: ChangeDetectorRef,
   //// public factorsService: FactorsService,
    private modalService: NgbModal
  ) {
   // this.factorsService.getMyFactors();
   // this.factorsService.getFeatureFlagsFromDB();
    this.communicationService.isEditProfileTrue$.subscribe((value) => {
      this.isProfileEditMode = value;
    });

    this.communicationService.isEditPhoneTrue$.subscribe((value) => {
      this.isPhoneEditMode = value;
    });

    this.communicationService.isResetPasswordSourceTrue$.subscribe((value) => {
      this.isPasswordResetMode = value;
    });

    
  }

  factorstoenroll: any[] = [];
  enrolledfactors: any[] = [];
  myfactors: any[] = [];
  smsFactors: any[] = [];
  qsnFactors: any[] = [];
  isFactorsEnabled = true;
  handleVerifySubmit() {

  }





  public userData: any;
  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    // Check if the cookie exists for the current router URL
    if (this.stepupService.getCookie('stepup')) {
      //this.router.url
      // Load the corresponding component based on the router URL
      if (this.router.url.includes('profile')) {
        this.loadProfileComponent();
      } else if (this.router.url.includes('phone')) {
        this.loadPhoneNumberComponent();
      } else if (this.router.url.includes('resetpassword')) {
        this.loadResetPasswordComponent();
      } 
    }
    //(window as any).sendHeightToParent();
  }
  ngAfterViewInit() {}

  /**
   * Load the profile component.
   */
  loadProfileComponent() {
    // Update the edit profile source variable
    this.communicationService.updateEditProfileSourceVariable(true);

    // Navigate to the profile component
    this.router.navigate([
      'myaccount',
      {
        outlets: {
          resetpassword: null,
          phone: null,
          profile: ['profile'],
          phonefactor: null,
          seceretfactors: null,
        },
      },
    ]);
  }
  /**
   * Loads the phone number component and updates the edit phone source variable.
   */
  loadPhoneNumberComponent() {
    // Update the edit phone source variable to true
    this.communicationService.updateEditPhoneSourceVariable(true);

    // Navigate to the phone number component
    this.router.navigate([
      'myaccount',
      {
        outlets: {
          resetpassword: null,
          phone: ['phone'],
          profile: null,
          phonefactor: null,
          seceretfactors: null,
        },
      },
    ]);
  }

  /**
   * Loads the reset password component.
   */
  loadResetPasswordComponent() {
    // Update the reset password source variable in the communication service
    this.communicationService.updateResetPasswordSourceVariable(true);

    // Navigate to the reset password component in the myaccount outlet
    this.router.navigate([
      'myaccount',
      {
        outlets: {
          resetpassword: ['resetpassword'],
          phone: null,
          profile: null,
          phonefactor: null,
          seceretfactors: null,
        },
      },
    ]);
  }
}
