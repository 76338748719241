import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
  inject,
  isDevMode,
} from '@angular/core';
import { SidenavService } from './services/sidenav.service';
import { SidenavContentAreaDirective } from '../myalcon-sidenav/sidenav-content-area.directive';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import {
  faBraille,
  faGear,
  faHome,
  faUser,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { environment } from '../../../../../environments/environment';
import { forkJoin, take } from 'rxjs';
import { ILanguageOption } from 'src/app/interfaces/language-option.interface';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FactorsService } from 'src/app/features/privacyand-security-settings/services/factotoenroll.service';
import { OktaService } from 'src/app/core/services/common/okta.service';
import { DeviceDetectionService } from 'src/app/device-detection.service';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';

@Component({
  selector: 'app-myalcon-sidenav',
  templateUrl: './myalcon-sidenav.component.html',
  styleUrls: ['./myalcon-sidenav.component.scss'],
})
export class MyalconSidenavComponent {
  [x: string]: any;
  resizingEvent = {
    // whether the user is currently resizing the sidenav
    isResizing: false,

    // the x coordinate of the mouse when the user started resizing
    startingCursorX: 0,

    // the width of the sidenav when the user started resizing
    startingWidth: 0,
  };
  public profile = this.profileService.UserProfile; // this.profileService.UserProfile;
  public myIcon: any = null;
  public ovalLogOutButton = 'oval-logout-button';
  public myfaHome: any = faHome;
  public myfaUser: any = faUser;
  public myfaGear: any = faGear;
  public myfaDashboard: any = faBraille;
  public myfaPhone: IconDefinition = faPhone;
  public menuData: any;
  public lang: any = 'en_US';
  public loading: boolean = false;
  showLangSelection = false;
  selectedMenuItem: string;
  selectedLang="English"
  url:any;
  showMenu = true;
  menuOpen: boolean = false;
  isIpadMiniPortrait: boolean;
  @Output() valueChange = new EventEmitter();
  @Output() langChange = new EventEmitter();
  @Input() closeNav: string;

  languageList: any =
    [
      
      { "locale": '', "language": "Brazilian Portuguese" },
      { "locale": '', "language": "Malaysian" }, 
      { "locale": '', "language": "Canadian French" },
      { "locale": '', "language": "Norwegian" }, 
      { "locale": '', "language": "Czech" },
      { "locale": '', "language": "Polish" },  
      { "locale": '', "language": "Danish" },
      { "locale": '', "language": "Portuguese" },  
      { "locale": '', "language": "Dutch" },
      { "locale": '', "language": "Romanian" }, 
      { "locale": 'en_US', "language": "English" },
      { "locale": '', "language": "Russian" },
      { "locale": '', "language": "Finnish" },  
      { "locale": '', "language": "Spanish" },
      { "locale": '', "language": "French" }, 
      { "locale": '', "language": "Swedish" },  
      { "locale": '', "language": "German" }, 
      { "locale": '', "language": "Thai" }, 
      { "locale": '', "language": "Greek" }, 
      { "locale": '', "language": "Turkish" },
      { "locale": '', "language": "Hungarian" }, 
      { "locale": '', "language": "Ukrainian" },
      { "locale": '', "language": "Indonesian" },
      { "locale": '', "language": "Vietnamese" },
      { "locale": '', "language": "Italian" },    
      { "locale": '', "language": "简体中文 (Simplified Chinese)" },
      { "locale": '', "language": "Japanese" },
      { "locale": '', "language": "繁體中文 (Traditional Chinese)" },
      { "locale": '', "language": "Korean" },           
   
    ];
  constructor(
    public sidenavService: SidenavService,
    private route: ActivatedRoute,
    private scroller: ViewportScroller,
    private communicationService: CommunicationService,
    private router: Router,
    public profileService: ProfileService,
    public factorsService: FactorsService,
    public oktaService :OktaService,
    private deviceDetectionService: DeviceDetectionService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {
    this.profileService.UserProfile.subscribe((data: any) => {
      this.translateService.addLangs(this.availableLanguages);
      if (navigator.language.replace('-', '_'))
      {
        this.translateService.setDefaultLang(navigator.language.replace('-', '_'));
        this.lang =navigator.language.replace('-', '_');
      }
      else if (data.gen_locale) {
        this.translateService.setDefaultLang(data.gen_locale);
        this.lang =data.gen_locale;
      }
      else
      {
        this.lang ='en_US';
        this.translateService.setDefaultLang('en_US');
      }
     
      this.buildLanguageOptions();
    });
  }

  changeLanguageValue(event: any) {
    if (event.target) {
      const selectedValue = event.target.value;
      this.lang = selectedValue;
      this.changeLanguage(selectedValue);
    }
  }
  
  private readonly translateService = inject(TranslateService);
  private readonly translatePipe = inject(TranslatePipe);
  textTranslatedByPipe = '';
  languageOptions: ILanguageOption[] = [];
  private readonly availableLanguages = ['en_US', 'en_AU'];

  changeLanguage(language: string) {
    //    alert(language);
    this.translateService.use(language);
    this.textTranslatedByPipe = this.translatePipe.transform(
      'TRANSLATED_BY_COMPONENT'
    );
  }
  private buildLanguageOptions() {
    const EN = this.translateService.get('EN');
    const AU = this.translateService.get('AU');

    forkJoin([EN, AU]).subscribe((_response) => {
      this.languageOptions = [
        {
          value: this.availableLanguages[0],
          label: _response[0].toUpperCase(),
        },
        {
          value: this.availableLanguages[1],
          label: _response[1].toUpperCase(),
        },
      ];
      this.textTranslatedByPipe = this.translatePipe.transform(
        'TRANSLATED_BY_COMPONENT'
      );
    });
    // console.log('current language,', this.translateService.currentLang)
  }

  @ViewChild(SidenavContentAreaDirective, { static: true })
  sidenavContentArea?: SidenavContentAreaDirective;
 
  isLanguageEnabled = true;
  ismyHomeEnabled = true;
  ismyAppsEnabled = true;
  ismyPrefrencesEnabled = true;
  ismyAccountEnabled = true;
  ismyConsentEnabled = true;
  ismyApprovalEnabled = true;
  ismyIdHelpEnabled = true;
  @Output() onMenuClicked = new EventEmitter<any>();
  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    // this.translateService.langChanges$.pipe(take(1)).subscribe(() => {
    //   // Your action or function to be executed after 50 seconds
    //  // console.log('After 50 seconds with RxJS timer');
    // });
    // console.log('close nav...',this.closeNav)
    this.showLangSelection = false;
    this.factorsService.getmyFeatureFlags();
    this.translateService.setDefaultLang('en_US');
    this.buildLanguageOptions();
    // localStorage.setItem('loginUrl',"https://d-identity.myalcon.com/reviewRequest?ticketNumber=c85f5c3e-fa66-4c19-b903-65197559bcb6&countryCode=US?utm_source=sfmc&utm_medium=email&utm_campaign=PROD_EMAIL_ADI_HOME_DYNAMIC&utm_term=%%Review_Request_link%%&utm_id=46700299&sfmc_id=204090571")

   this.getredirecturl();
   this.getResolution();
    // Set the icon for the user.
    this.myIcon = faUser;

    // Set the CSS class for the logout button.
    this.ovalLogOutButton = 'oval-logout-button';
    
    // Set the menu data.
  }
  ngOnChanges(): void {
    // console.log('on ngon chnage..',this.closeNav)
    this.showMenu = false;
    // this.communicationService.isMenuClosed$.subscribe(isClosed => {
    //    this.menuOpen = !isClosed;
    //    });
   
  } 
 
  mobileView = false;
  tabView = false;
  desktopView = true;
 
  getResolution() {
    this.checkOrientation();
    this.mobileView = false;
    this.tabView = false;
    this.desktopView = false;
    this.deviceDetectionService.isHandset().subscribe(isHandset => { 
      this.mobileView = isHandset; 
     
    }); 
      this.deviceDetectionService.isTablet().subscribe(isTablet => { 
        this.tabView = isTablet; 
      
      }); 
      this.deviceDetectionService.isWeb().subscribe(isWeb => { 
        this.desktopView = isWeb; 
      
      });
   
  }
  getredirecturl(){
  
    const redirectUrl:any = localStorage.getItem('loginUrl')
      ? localStorage.getItem('loginUrl')
      : this.router.url;
    
    
      if (localStorage.getItem('loginUrl')) {
        this.url = new URL(redirectUrl);
        if (this.url.searchParams.get('ticketNumber')) {
          this.menuData = [
            {
              route: 'myapprovals',
              label: 'mnuapproval',
              faImage: '../assets/img/personal-icon.png',
              useHref:false,
              scrollapplicable: false,
              show: this.ismyApprovalEnabled,
            }
          ]
          this.router.navigate(['/myapprovals'])
        }
      }else{
        this.menuOptions();
      }
  }
menuOptions(){
  this.factorsService.getFeatureFlags().subscribe((features) => {
    debugger;
    if (features) {
      this.ismyConsentEnabled = features['myconsent'];
      this.isLanguageEnabled = features['language-selection'];
      this.ismyAccountEnabled = features['myaccount'];
      this.ismyHomeEnabled = features['home'];
      this.ismyPrefrencesEnabled = features['mypreference'];
      this.ismyAppsEnabled = features['myalconapps'];
      // this.ismyApprovalEnabled = features.filter(
      //   (item: { feature: string; flag: boolean }) =>
      //     item.feature === 'myapprovals'
      // )[0].flag;
      this.menuData = [
        {
          route: 'home',
          label: 'mnumyalconhome',
          faImage: '../assets/home.png',
          scrollapplicable: false,
          useHref:false,
          show: this.ismyHomeEnabled,
        },
        {
          useHref: true,
          route: 'https://www.myalcon.com',
          label: 'mnumyapplications',
          faImage: '../assets/apps.png',
          scrollapplicable: false,
          show: this.ismyAccountEnabled,
        },
        {
          route: 'myaccount',
          label: 'mnumpersonnelinformation',
          faImage: '../assets/img/personal-icon.png',
          useHref:false,
          scrollapplicable: false,
          show: this.ismyConsentEnabled,
        },
        {
          route: 'mypreference',
          label: 'mnumanagepreferences',
          faImage: '../assets/img/bell.png',
          useHref:false,
          scrollapplicable: false,
          show: this.ismyPrefrencesEnabled,
        },
        {
          route: 'privacyandsecuritysettings',
          label: 'mnuprivacysecurity',
          faImage: '../assets/img/alert.png',
          useHref:false,
          scrollapplicable: false,
          show: this.ismyAppsEnabled,
        },
        {
          route: 'https://www.myalcon.com/international/professional/myalconid/faqs/',
          label: 'mnumyidhelp',
          faImage: '../assets/img/help-icon.png',
          useHref:true,
          scrollapplicable: false,
          show: this.ismyIdHelpEnabled,
        }
       
      ];
    }
  });
}
  private clearOktaCookies() {
    // Replace 'oktaDomain' with your Okta domain (e.g., 'dev-123456.okta.com')
    const oktaDomain = environment.myalconaccOktaProfileUS.domain;

    // Clear Okta cookies
    document.cookie.split(';').forEach((c) => {
      const name = c.replace(/^\s+/g, '').split('=')[0];
      if (name.indexOf(oktaDomain) !== -1) {
        document.cookie =
          name +
          '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' +
          oktaDomain;
      }
    });
  }
  /**
   * Logout function.
   *
   * This function logs out the user by performing the following steps:
   *  - If the parent window is not equal to the current window, it sends a logout message to the parent window.
   *  - If the parent window is equal to the current window, it performs the following actions:
   *    - Shows an alert indicating that the user is outside of an iframe.
   *    - Calls the signOut method of the oktaAuth object to sign out the user.
   *    - Logs a success message if the signOut operation is successful.
   *    - Logs an error message if the signOut operation fails.
   *    - Calls the clearOktaCookies method to clear any Okta-related cookies.
   *    - Shows an alert indicating that the user is cd of an iframe.
   *  - If an error occurs during the logout process, it logs an error message.
   */
  async logout() {
    //  window.location.href = 'https://dev2-axon.myalcon.com/oauth2/aus5kk17b3KfCwjA91d7/v1/logout?id_token_hint=' + this.oktaAuth.getIdToken();
    this.oktaService.logoutSessions();
   
  }

  @HostBinding('class.resizing')
  get isResizing(): boolean {
    return this.resizingEvent.isResizing;
  }

  @HostBinding('class.is-expanded')
  get isExpanded() {
    return this.sidenavService.isExpanded;
  }

  startResizing(event: MouseEvent): void {
    this.resizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.sidenavService.sidenavWidth,
    };
  }

  @HostListener('window:mousemove', ['$event'])
  updateSidenavWidth(event: MouseEvent) {
    if (!this.resizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = event.clientX - this.resizingEvent.startingCursorX;

    const newWidth = this.resizingEvent.startingWidth + cursorDeltaX;

    this.sidenavService.setSidenavWidth(newWidth);
  }

  @HostListener('window:mouseup')
  stopResizing() {
    this.resizingEvent.isResizing = false;
  }
  closeMenu(){      
    this.menuOpen = false;   
    this.valueChange.emit('close');   
    
  }
  onMenu(event : Event){       
    this.menuOpen = false;
    this.onMenuClicked.emit(true);  
        
  }
  onMenuSelect(menu: any): void { 
    this.selectedMenuItem = menu.label; 
  }
  onLangDropdown(){
    this.langChange.emit('lang clicked');
    this.showLangSelection = true;
  }
  onLangSelect(lang: any){
    this.selectedLang = lang.language;
    if (lang.locale) {
      const selectedValue = lang.locale;
      this.lang = selectedValue;
      this.changeLanguage(selectedValue);
    }
    this.showLangSelection=false;
  }
  checkOrientation(): void {
    this.isIpadMiniPortrait = this.deviceDetectionService.isIpadMiniPortrait();

  }
}
