<form [formGroup]="profileForm" (ngSubmit)="onProfileSubmit()">
  <div *ngIf="isError" class="error-text alert alert-danger">                      
    {{isError}}     </div>
  <div class="input-group">
    <div class="form-group mx-2 col-md-3 col-xl-3">
      <label for="title"> {{ 'title'  | translate}}</label>
      <input type="text" class="form-control mx-1" required id="title" formControlName="title" aria-describedby="title" />
    </div>
    <div class="form-group mx-2 col-md-4 col-xl-4">
      <label for="firstName"> {{ 'fanme'  | translate}}</label>
      <input type="text" class="form-control mx-1" required formControlName="firstName" id="firstName"
        aria-describedby="button-addon2" />
    </div>
    <div class="form-group mx-2 col-md-4 col-xl-4">
      <label for="Surname"> {{ 'surname'  | translate}}</label>
      <input type="text" class="form-control mx-1" required id="Surname" placeholder="Surname" formControlName="lastName"
        aria-describedby="button-addon2" />
    </div>
  </div>
  <div>
    <div *ngIf="invalidData" class="error-text">
      <span class="bi bi-exclamation-circle-fill" aria-label="Error"></span>
      {{ 'myaccountprofileheadererr'  | translate}}
    </div>
  </div>
  <div class="text-end my-2">
    <button class="btn text-end btn-reset mx-2 my-1" (click)="reset($event)" type="reset">
      {{ 'btnreset'  | translate}}
    </button>
    <button class="btn btn-cancel mx-2 my-1" (click)="cancelBtn()">
      {{ 'btncancel'  | translate}}
    </button>

    <button class="btn btn-submit mx-2 my-1" type="submit" [disabled]="!profileForm.valid">
            {{ 'btnsubmit'  | translate}}
    </button>
    
  </div>
</form>
