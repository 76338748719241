import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MyaccountService } from 'src/app/features/myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
import { FactorsService } from '../../services/factotoenroll.service';
import { catchError } from 'rxjs';
import { AuditUserLogsService } from 'src/app/core/services/common/audit-user-logs.service';

@Component({
  selector: 'app-edit-secret-question-factor',
  templateUrl: './edit-secret-question-factor.component.html',
  styleUrls: ['./edit-secret-question-factor.component.scss'],
})
export class EditSecretQuestionFactorComponent {
  @Output() cancelAcc = new EventEmitter();
  @Output() refreshEnrollmentEvent = new EventEmitter<string>();
  public userData: any;
  secretQuestionForm: any = new FormGroup({
    secretquestion: new FormControl(undefined, [Validators.required]),
    secretquestionasnwer: new FormControl(undefined, [Validators.required]),
  });
  questions: any[] = [];
  userid = '';
  question = '';
  constructor(
    public myaccountService: MyaccountService,
    public profileService: ProfileService,
    private route: Router,
    private http: HttpClient,
    private audit : AuditUserLogsService,
    public communcationService: CommunicationService,
    public factorsService: FactorsService
  ) {
    this.getUserFormData();
  }

  ngOnInit(): void {
    // Initialization code if needed
  }

  ngOnDestroy(): void {
    // Unsubscribe from subscriptions to avoid memory leaks
   // this.userData.unsubscribe();
  }

  getSecretQuestions(): void {
    this.factorsService.getSecretQuestions(this.userid)
      .subscribe(questions => {
        this.questions = questions;
      });
  }

  onSubmit(): void {
    // Handle form submission
  }

  async getUserFormData() {
    this.userData = this.profileService.UserProfile.subscribe((data: any) => {
      this.userData = data;
      this.userid = data.sub;
      this.getSecretQuestions();
    });
  }

  addQuestionFactor(userid: string, secretQuestionbody: any): void {
    this.factorsService.addFactor(userid, secretQuestionbody)
      .subscribe(response => {
          if(response!.errorCode!)
        {
          var sqerSummary = response.errorSummary;
        var sqerrorsummary = response.errorCauses.map((response: { errorSummary: any; }) => response.errorSummary).join(", ");;
        this.communcationService.updateResetSecretQuestionFactorVariable(false,sqerSummary + '-' +sqerrorsummary);
        return;    
        }
        // Process the response here
      //  console.log(response);
        this.factorsService.getMyFactors();
        this.audit.postAuditLogs("SUCCESS",userid,"Factors (Secret Question) Pages",secretQuestionbody);
        // this.sendMessage();
      }),
     (error:Error)=>{
      console.log('api failure error....',error);
      this.audit.postAuditLogs("FAILURE",userid,"Factors (Secret Question) Pages",error);
     }

   
      
  }

  async submitBtn() {
    this.userData = this.profileService.UserProfile.subscribe((data: any) => {
      this.userData = data;
      const body = {
        factorType: 'question',
        provider: 'OKTA',
        profile: {
          question: this.secretQuestionForm.value.secretquestion,
          answer: this.secretQuestionForm.value.secretquestionasnwer,
        },
      };
      this.addQuestionFactor(data.sub, body);
      this.communcationService.cancelFactorResetAll(false);
    }), (error:Error)=>{
      console.log('api failure error....',error)
     }

  }

  sendMessage() {
    this.refreshEnrollmentEvent.emit();
  }

  reset(event: any) {
    event.preventDefault();
    this.getUserFormData();
  }

  cancelBtn() {
    this.communcationService.cancelFactorResetAll(false);
  }
}
