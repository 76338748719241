import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faPhone, faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FactorsService } from '../privacyand-security-settings/services/factotoenroll.service';

@Component({
  selector: 'app-myalcon-home',
  templateUrl: './myalcon-home.component.html',
  styleUrls: ['./myalcon-home.component.scss'],
})
export class MyalconHomeComponent implements OnInit {
  public loading: boolean = true;
  ismyUniversalLogoutEnabled = false;
  SignOut: any;

  cardData: any;
  imgcardSalesRepData: any;
  //public title= 'home_title';

  myFunction() {
    alert('Function executed in parent component');
  }
  constructor(
    private route: ActivatedRoute,
    public factorsService: FactorsService
  ) {}
  ngOnInit(): void {
    this.factorsService.getFeatureFlags().subscribe((features) => {
      if (features) {
        this.ismyUniversalLogoutEnabled = features['universal_logout'];
        // Account over view card data
        // This object is  built keeping in mind we can use an api call instead
        this.cardData = [
          {
            title: 'tile0_title', /// key of from the locale.json
            text: 'tile0_text', /// key of from the locale.json
            link: 'https://www.myalcon.com/professional/',
            isShow: true,
            buttonkey: 'manageapplication',
            faImage: faHome,
            function: '',
            isHreflink: true
          },
          {
            title: 'tile1_title', /// key of from the locale.json
            text: 'tile1_text', /// key of from the locale.json
            link: '/myaccount',
            isShow: true,
            buttonkey: 'updateinformattion',
            faImage: faHome,
            function: '',
            isHreflink: false
          },
          {
            title: 'tile2_title', /// key of from the locale.json
            text: 'tile2_text', /// key of from the locale.json
            link: '/mypreference',
            isShow: true,
            buttonkey: 'updatepreferencebtn',
            faImage: faHome,
            function: '',
            isHreflink: false
          },
          {
            title: 'tile5_title', /// key of from the locale.json
            text: 'tile5_text', /// key of from the locale.json
            link: '/privacyandsecuritysettings',
            isShow: this.ismyUniversalLogoutEnabled,
            buttonkey: 'updatesettings',
            faImage: faHome,
            function: '',
            isHreflink: false
          },
          {
            title: 'tile3_title', /// key of from the locale.json
            text: 'tile3_text', /// key of from the locale.json
            link: '/',
            isShow: false,
            buttonkey: 'btnedit',
            faImage: faHome,
            function: '',
            isHreflink: false
          },
          {
            title: 'tile2_title', /// key of from the locale.json
            text: 'tile2_text', /// key of from the locale.json
            link: '/mypreference',
            buttonkey: 'btnedit',
            isShow: false,
            faImage: faHome,
            function: '',
            isHreflink: false
          },
        ];

        // My Alcon Support   view card data
        // This object is  built keeping in mind we can use an api call instead
        this.imgcardSalesRepData = [
          {
            title: 'myaclconsupporttilestile1_title', /// key of from the locale.json
            text: 'myaclconsupporttilestile1_primarytext', /// key of from the locale.json
            subtext: '',
            linkone: 'myaclconsupporttilestile1_primaryconatact', /// key of from the locale.json
            secondtext: 'myaclconsupporttilestile1_secondrytext', /// key of from the locale.json
            linktwo: 'myaclconsupporttilestile1_secondryconatact', /// key of from the locale.json
            faImage: faPhone, ///  Card Icon
            fragment: '',
            type: 'tel',
          },
          {
            title: 'myaclconsupporttilestile2_title', /// key of from the locale.json
            text: 'myaclconsupporttilestile2_primarytext', /// key of from the locale.json
            subtext: 'myaclconsupporttilestile2_subtext',
            linkone: '', // text is not applicable for the card property
            secondtext: 'myaclconsupporttilestile2_secondrytext', // text is not applicable for the card property
            linktwo: '', // text is not applicable  for the card property
            faImage: faHome, ///  Card Icon for the card property
            fragment: '',
            type: 'tel', // Telephine prefix
          },
          {
            title: 'myaclconsupporttilestile3_title', /// key of from the locale.json
            text: 'myaclconsupporttilestile3_primarytext', /// key of from the locale.json
            subtext: '',
            linkone: 'myaclconsupporttilestile3_primaryconatact', /// key of from the locale.json
            secondtext: 'myaclconsupporttilestile3_secondrytext', /// key of from the locale.json
            linktwo: 'myaclconsupporttilestile3_secondryconatact', /// key of from the locale.json
            faImage: faEnvelope, ///  Card Icon
            fragment: '', ///  text is not applicable  for the card property
            type: 'mailto', /// Email prefix
          },
        ];
      }
    });
    setTimeout(() => {
      this.loading = false;
    }, 700);
    //(window as any).sendHeightToParent();
  }

  afterViewInit() {}
}
