import { Injectable, NgZone } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectionService {
  constructor(private breakpointObserver: BreakpointObserver, private ngZone: NgZone) { }

  isHandset(): Observable<boolean> {
    return this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(result => result.matches)
    );
  }

  isTablet(): Observable<boolean> {
    return this.breakpointObserver.observe(Breakpoints.Tablet).pipe(
      map(result => result.matches)
    );
  }

  isWeb(): Observable<boolean> {
    return this.breakpointObserver.observe(Breakpoints.Web).pipe(
      map(result => result.matches)
    );
  }
  onResize(): Observable<{ width: number; height: number }> {
    return fromEvent(window, 'resize').pipe(
      debounceTime(200), // Add a debounce time to avoid excessive events
      map(() => ({
        width: window.innerWidth,
        height: window.innerHeight,
      }))
    );
  }
  isPortrait(): boolean {
    return window.innerHeight > window.innerWidth;
  }

  isIpadMiniPortrait(): boolean {
    const isiPadMini = /iPad/.test(navigator.userAgent) && 
                       window.screen.height >= 1024 && window.screen.height <= 1080 && 
                       window.screen.width >= 768 && window.screen.width <= 834;
    return this.isPortrait() && isiPadMini;
  }

 
}
