import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyaccountService } from '../../myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
import { ConfrimModalComponent } from '../../myalcon-account/confirmation.popup-modal.component';
import { Observable, Subscribable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StepupService } from 'src/app/core/stepup/stepup.service';
import { FactorsService } from '../services/factotoenroll.service';


@Component({
  selector: 'app-privacyandsecurity-settings',
  templateUrl: './privacyandsecurity-settings.component.html',
  styleUrls: ['./privacyandsecurity-settings.component.scss']
})
export class PrivacyandsecuritySettingsComponent implements OnInit {

  invalidData: any;
  public isPhoneFactorEditMode: boolean = false;
  public isSecretQuestionFactorEditMode: boolean = false;
  IsEditProfileEnabled: any;
  factorstoenroll: any[] = [];
  enrolledfactors: any[] = [];
  myfactors: any[] = [];
  smsFactors: any[] = [];
  qsnFactors: any[] = [];
  //isFactorsEnabled = true;
  profile: Observable<unknown> | Subscribable<unknown> | Promise<unknown>;
  userData: any;
  userid: any;
  enablePhoneFactorDisableButton = false;
  enableSecretQuestionDisablesButton = false;
  isPhoneFactorErrorMsg = '';
  isSecretQuestionFactorErrorMsg = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private stepupService: StepupService,
    private cdr: ChangeDetectorRef,
    public factorsService: FactorsService,
    private modalService: NgbModal,
    private profileService: ProfileService,
  ) {
    
    // get all factors applicable for the user fill the behavious subject in service
    this.factorsService.getMyFactors();

    //
    //this.factorsService.getFeatureFlagsFromDB();
   
    // get the latest values and assign it to local varaibles to handle dispaly in UI
    this.communicationService.isPhoneFactorSourceTrue$.subscribe((value) => {
      this.isPhoneFactorEditMode = value;
    });
    this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe(
      (value) => {
        this.isSecretQuestionFactorEditMode = value;
      }
    );
    this.communicationService.isPhoneFactorErrorSummaryTrue$.subscribe((value) => {
      this.isPhoneFactorErrorMsg = value;
    });
    this.communicationService.isSecurityQuestionErrorSummaryTrue$.subscribe((value) => {
      this.isSecretQuestionFactorErrorMsg = value;
    });

    // to set and display UI element using local varibale value setting
    this.getMyFactors();


  }
  ngOnInit(): void {

    // based on the roaute load the childe components
    if (this.router.url.includes('phonefactor')) {
      this.loadPhoneFactorComponent();
    } else if (this.router.url.includes('seceretfactors')) {
      this.loadSecretFactorComponent();
    }
  }

  /**
   * Loads the Phone Factor component.
   */
  loadPhoneFactorComponent() {
    this.isSecretQuestionFactorErrorMsg = '';
    this.isPhoneFactorErrorMsg = '';
    // Update the reset password source variable in the communication service
    this.communicationService.updatePhoneFactoreVariable(true);
    this.communicationService.updateResetSecretQuestionFactorVariable(false);

    //this.openPopup();
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.communicationService.isPhoneFactorSourceTrue$.subscribe((value) => {
        this.isPhoneFactorEditMode = value;
      });

      this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe((value) => {
        this.isSecretQuestionFactorEditMode = value;
      });

      //modalRef.close();
      this.getMyFactors();
      this.router.navigate([
        'privacyandsecuritysettings',
        {
          outlets: {
            phonefactor: ['phonefactor'],
            seceretfactors: null,
          },
        },
      ]);
      modalRef.close();
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.communicationService.updatePhoneFactoreVariable(false);
      this.communicationService.updateResetSecretQuestionFactorVariable(false);
      this.communicationService.isPhoneFactorSourceTrue$.subscribe((value) => {
        this.isPhoneFactorEditMode = value;
      });
      this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe((value) => {
        this.isSecretQuestionFactorEditMode = value;
      });

      this.getMyFactors();
      modalRef.close();
    });
    // Navigate to the reset password component in the myaccount outlet
  }

  /**
   * Loads the Secret Qsn component.
   */
  loadSecretFactorComponent() {
    this.isSecretQuestionFactorErrorMsg = '';
    this.isPhoneFactorErrorMsg = '';
    // Update the reset password source variable in the communication service
    this.communicationService.updateResetSecretQuestionFactorVariable(true);
    this.communicationService.updatePhoneFactoreVariable(false);
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe(
        (value) => {
          this.isSecretQuestionFactorEditMode = value;
        }
      );
      this.communicationService.isPhoneFactorSourceTrue$.subscribe(
        (value) => {
          this.isPhoneFactorEditMode = value;
        }
      );
      //modalRef.close();
      this.getMyFactors();
      this.router.navigate([
        'privacyandsecuritysettings',
        {
          outlets: {
            phonefactor: null,
            seceretfactors: ['seceretfactors'],
          },
        },
      ]);
      modalRef.close();
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.communicationService.updateResetSecretQuestionFactorVariable(false);
      this.communicationService.updatePhoneFactoreVariable(false);
      this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe(
        (value) => {
          this.isSecretQuestionFactorEditMode = value;
        }
      );
      this.communicationService.isPhoneFactorSourceTrue$.subscribe(
        (value) => {
          this.isPhoneFactorEditMode = value;
        }
      );
      this.getMyFactors();
      modalRef.close();
    });
    // Navigate to the reset password component in the myaccount outlet
  }

  // service call to remove / disable already enabled phone factor
  disablePhoneFactorComponent() {
    //this.openPopup();
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
      this.userData = this.profileService.UserProfile.subscribe((data: any) => {
        this.userData = data;
        this.userid = data.sub;
        this.qsnFactors = this.enrolledfactors.filter(
          (factor) => factor.factorType === 'sms'
        );
        if (this.qsnFactors.length == 0) {
          alert('There is no phone factor to disable')
        }
        this.factorsService
          .deleteFactor(this.qsnFactors[0].id, data.sub)
          .subscribe((response) => {
            // Process the response here
            this.factorsService.getMyFactors();
            //    console.log(response);
          });
      });
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    });

  }

  // service call to remove / disable already Secret QSN factor
  disableSecretFactorComponent() {
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
      this.userData = this.profileService.UserProfile.subscribe((data: any) => {
        this.userData = data;
        this.userid = data.sub;
        this.qsnFactors = this.enrolledfactors.filter(
          (factor) => factor.factorType === 'question'
        );
        this.factorsService
          .deleteFactor(this.qsnFactors[0].id, data.sub)
          .subscribe((response) => {
            // Process the response here
            this.factorsService.getMyFactors();
            //  console.log(response);
          });
      });
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    });
    // this.openPopup();

  }

  //local mehtod to get the factors and display the factor based on the values
  getMyFactors(): void {
    this.factorsService.getenrolledFactors().subscribe((features) => {
      this.enrolledfactors = features;
    });
    this.factorsService.getfactorsToEnrolled().subscribe((factorstoenroll) => {
      this.factorstoenroll = factorstoenroll;
      if (this.factorstoenroll) {
        this.smsFactors = this.factorstoenroll!.filter(
          (factor) => factor.factorType === 'sms'
        );
        this.qsnFactors = this.factorstoenroll!.filter(
          (factor) => factor.factorType === 'question'
        );
        if (this.smsFactors.length > 0) {
          this.enablePhoneFactorDisableButton = true;
        }
        else {
          this.enablePhoneFactorDisableButton = false;

        }
        if (this.qsnFactors.length > 0) {

          this.enableSecretQuestionDisablesButton = true;
        }
        else {
          this.enableSecretQuestionDisablesButton = false;
        }

      }
    });
    // this.myfactors = this.factorstoenroll!.filter((factor) =>
    //   this.enrolledfactors.includes(factor)
    // );
  }

  // pop up modal helper method
  openPopup(): void {
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();

    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    });
  }
}
