import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { MyaccountService } from 'src/app/features/myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
import { FactorsService } from '../../services/factotoenroll.service';
import { PhoneFactorPopupModalComponent } from './edit-phone-factor.popup-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { AuditUserLogsService } from 'src/app/core/services/common/audit-user-logs.service';
@Component({
  selector: 'app-edit-phone-factor',
  templateUrl: './edit-phone-factor.component.html',
  styleUrls: ['./edit-phone-factor.component.scss'],
})
export class EditPhoneFactorComponent {
  @Output() cancelAcc = new EventEmitter();
  public userData: any;

  invalidNo = false;
  inphone: any;
  isValidPhoneNumber: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneForm: any = new FormGroup({
    phone: new FormControl(undefined, [Validators.required, Validators.minLength(5)]),
  });

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  constructor(
    public myaccountService: MyaccountService,
    public profileService: ProfileService,
    private route: Router,
    public communcationService : CommunicationService,
    public factorsService :  FactorsService,
    private audit : AuditUserLogsService,
    private modalService: NgbModal, private domSanitizationService: DomSanitizer
  ) {}
  ngOnInit(): void {
    this.getUserFormData();


  }

  addSMSFactor(userid:string, secretQuestionbody: any): void {
    this.factorsService.addFactor(userid, secretQuestionbody)
      .subscribe(response => {
        // Process the response here
       // console.log(response);
        if(response!.errorCode!)
        {
        this.invalidNo = true;    
        var fferSummary = response.errorSummary;  
        var fferrorsummary = response.errorCauses.map((response: { errorSummary: any; }) => response.errorSummary).join(", ");;
        this.communcationService.updatePhoneFactoreVariable(false,fferSummary + '-' +fferrorsummary);
        return;    
        }
        this.openPopup(response.id,userid);
        this.audit.postAuditLogs("SUCCESS",userid,"Factors (Phone) Pages",secretQuestionbody);

      }),
      (error:Error)=>{
       console.log('api failure error....',error);
       this.audit.postAuditLogs("FAILURE",userid,"Factors (Phone) Pages",error);
      };
  }


  async getUserFormData() {
    this.userData = this.profileService.UserProfile.subscribe((data: any) => {
      this.userData = data;
      this.phoneForm.patchValue({
        phone: data.myacc_phone_no.substring(4).trim(),
      });
    });


  }
  async onChangeCode(event :any){
    // const temp = this.phoneForm.controls.value;
    // // const phoneUser =temp.internationalNumber.toString().substring(4).trim();
    // console.log(this.phoneForm.controls.phone.pendingValue,this.phoneForm.controls.phone)
  }

  onPhoneNumberSubmit() {
    //this.openPopup();
    if (this.phoneForm.invalid) {
      this.invalidNo = true;
    } else {
      this.invalidNo = false;
      let phone: any = this.phoneForm.value;
      this.userData = this.profileService.UserProfile.subscribe((data: any) => {
        this.userData = data;
        const body = {
          factorType: 'sms',
          provider: 'OKTA',
          profile: {
            phoneNumber: phone?.phone?.internationalNumber, // "disliked_food",
          },
        };
        this.addSMSFactor(data.sub, body);
        //this.audit.postAuditLogs();

        this.communcationService.cancelFactorResetAll(false);
      });
      this.cancelBtn() ;

    }
  }
  reset(event: any) {
    event.preventDefault();
    this.getUserFormData();
  }
  cancelBtn() {
    this.communcationService.cancelFactorResetAll(false);
  }
  public user = {
    name: 'Izzat Nadiri',
    age: 26
    }
  openPopup(factorId:string,userid:string): void {
    const modalRef = this.modalService.open(PhoneFactorPopupModalComponent)
    modalRef.componentInstance.userid = userid;
    modalRef.componentInstance.factorid = factorId;
    modalRef.componentInstance.verifyOTP.subscribe((receivedEntry: any) => {
      this.factorsService.getMyFactors();
      console.log(receivedEntry);
      modalRef.close();
      })
      modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
        // alert('close fired');
        this.factorsService.getMyFactors();
        modalRef.close();
        })
    }

}
