import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavLinkComponent } from './core/layout/components/sidenav-link/sidenav-link.component';
import { MobileNavComponent } from './core/layout/components/mobile-nav/mobile-nav.component';
import { TopnavComponent } from './core/layout/components/topnav/topnav.component';
import { NgbActiveModal, NgbCollapseModule, NgbDropdownModule, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImgCardComponent } from './core/commoncomponent/img-card/img-card.component';
import { FlatCardComponent } from './core/commoncomponent/flat-card/flat-card.component';
import { OKTA_CONFIG, OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { LayoutComponent } from './core/layout/layout/layout.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import appconfig from 'src/config/appconfig';
import { TranslateLoader, TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { StepupModule, StepupModuleConfig } from './core/stepup/stepup.module';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyAlconHomeModule } from './features/myalcon-home/myalcon-home.module';
import { MyalconSidenavComponent } from './core/layout/components/myalcon-sidenav/myalcon-sidenav.component';
import { SharedModule } from "./shared.module";
import { MyalconAccountModule } from './features/myalcon-account/myalcon-account.module';
//import { AppsService } from './features/myalcon-apps/services/apps.service';
import { FirstCharPipe } from './core/pipes/fistChar';
import { PrivacyandSecuritySettingsModule } from './features/privacyand-security-settings/privacyand-security-settings.module';
import { PrivacyandsecuritySettingsRoutingModule } from './features/privacyand-security-settings/privacyandsecurity-settings/privacyandsecurity-settings-routing.module';

import { LoginComponent } from './login/login.component';
import { Preference } from './features/myalcon-preferences/myalcon-preferences.component';
import { MyalconPreferencesModule } from './features/myalcon-preferences/myalcon-preferences.module';
import { UniqueArrayPipe } from './core/pipes/filterUnique';
import { OktaService } from 'src/app/core/services/common/okta.service';

let authConfig = appconfig.oidcus;
let region ='americas';
let regionlocalstorage =window.sessionStorage.getItem("region");
if(regionlocalstorage)
{
    region = regionlocalstorage;
}
else  {
  if (window.location.href.endsWith('americas'))
  {
     region = 'americas';
  }
  if (window.location.href.endsWith('emea')) 
  {
     region = 'emea';
  }
  if (window.location.href.endsWith('apac'))
  {
     region = 'apac';
  }
  
}
window.sessionStorage.setItem("region",region)
if (region == "apac") {
  authConfig = appconfig.oidcap;
 
}
else if (region == "emea") {
  authConfig = appconfig.oidceu;
  
}  
else if (region == "americas") {
  authConfig = appconfig.oidcus;
 
}

const oktaAuth = new OktaAuth(authConfig);
const moduleConfig: OktaConfig = { oktaAuth };

const stepupConfig: StepupModuleConfig = {
  config: { durationInSeconds: 360, acrValues: 'urn:okta:loa:1fa:any' },
};

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const I18N_CONFIG = {
  defaultLanguage: 'en_US', // this name need to be the same as the JSON file
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
}

@NgModule({
    declarations: [
        AppComponent,
        SidenavLinkComponent,
        ImgCardComponent,
        FlatCardComponent,
        TopnavComponent,
        MobileNavComponent,
        LayoutComponent,
        MyalconSidenavComponent,
        LoginComponent,
        //UniqueArrayPipe
    ],
    providers: [NgbActiveModal,TranslatePipe,FirstCharPipe,
        {
            provide: APP_INITIALIZER,
            deps: [ProfileService],
            useFactory: (profileService: ProfileService
              ) => () => {
                if (
                  window.location.href.includes('reviewRequest') 
                ) {
                  localStorage.setItem('loginUrl', window.location.href);                 
                }
                // Subscribe to authState change event.
                oktaAuth.authStateManager.subscribe(function (authState) {
                    // Logic based on authState is done here.
                    if (!authState.isAuthenticated) {
                        //alert('Vinay  logged out');
                        // User is not authenticated, do something
                        return;
                    }
                    //alert(3);
                    // alert('Vinay  logged in');
                    // User is authenticated, do something else
                    profileService.loadUserProfile().then(claims => {
                      const lang1: string = claims['gen_locale'].toString();
                        
                        
                    });

                });
            },
            multi: true
        },
        {
            provide: OKTA_CONFIG,
            useValue: { oktaAuth },
        },
    ],
    bootstrap: [AppComponent],
    imports: [
        OktaAuthModule,
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        MatIconModule,
        NgbCollapseModule,
        HttpClientModule,
        FontAwesomeModule,
        NgxIntlTelInputModule,
        BrowserAnimationsModule,
        OktaAuthModule.forRoot(moduleConfig),
        //  NgIdleModule.forRoot(),
        StepupModule.forRoot(stepupConfig),
        FormsModule,
        ReactiveFormsModule,
        MyAlconHomeModule,
        SharedModule,
        MyalconAccountModule,
        MyalconPreferencesModule,
        PrivacyandSecuritySettingsModule,
        NgbDropdownModule,
        NgbModule,
        MyalconPreferencesModule,
        TranslateModule.forRoot(I18N_CONFIG)

    ]
})


export class AppModule {

  constructor(private readonly router: Router,public oktaService:OktaService) {
    //console.log(window.location.origin);
   // console.log('Entered in to CSA Portal');
    //router.events
    //  .subscribe(console.log)
  
    if (
  
      window.location.href.includes('login') &&
  
      window.location.href.includes('callback') &&
      window.location.href.includes('error_description')
    ) {
      if (window.location.href.includes('error=')) {
        this.oktaService.logoutSessions();
      }
    }
  }
}
