<form
  #f="ngForm"
  [formGroup]="resetPasswordForm"
  (ngSubmit)="onResetPasswordSubmit()"
>
  <div>
    <label>{{ 'confirnewmpassword'  | translate}}</label>
    <div class="input-group">
      <input
        type="password"
        class="form-control input-space col-md-5 col-xl-5 my-2"
        formControlName="oldpassword"
        (keyup)="onNewPassword($event)"
        (focusout)="onNewPassword($event)"
        (blur)="onNewPassword($event)"
        (mouseout)="onNewPassword($event)"
        aria-describedby="button-addon2"
        [attr.type]="newPasswordVisible ? 'text' : 'password'"
      />
      <i
        (click)="togglenewPasswordVisibile()"
        class="bi bi-eye-slash eye-icon col-md-5 col-xl-5"
        [ngClass]="newPasswordVisible ? 'bi bi-eye' : 'bi bi-eye-slash'"
      ></i>
    </div>  



    <div *ngIf="passwordValidation">
      <ul class="validation-text pl-10">
        <li>
          <i class="bi bi-check" *ngIf="!length"></i>
          <i class="bi bi-exclamation-circle-fill" *ngIf="length"></i>
          <span class="mx-2">{{'atleast8char' | translate}}</span>
        </li>
        <li>
          <i class="bi bi-check" *ngIf="lowerCase"></i>
          <i class="bi bi-exclamation-circle-fill" *ngIf="!lowerCase"></i>
          <span class="mx-2">{{'atleatlowercase' | translate}}</span>
        </li>
        <li>
          <i class="bi bi-check" *ngIf="upperCase"></i>
          <i class="bi bi-exclamation-circle-fill" *ngIf="!upperCase"></i>
          <span class="mx-2">{{ 'atleatuppercase' | translate}}</span>
        </li>
        <li>
          <i class="bi bi-check" *ngIf="number"></i>
          <i class="bi bi-exclamation-circle-fill" *ngIf="!number"></i>
          <span class="mx-2">{{'anumber' | translate }}</span>
        </li>
        <li>
          <i class="bi bi-check" *ngIf="!emailCheck"></i>
          <i class="bi bi-exclamation-circle-fill" *ngIf="emailCheck"></i>
          <span class="mx-2">{{'nopartmail' | translate }}</span>
        </li>
        <li>
          <i class="bi bi-check" *ngIf="!firstNameCheck"></i>
          <i class="bi bi-exclamation-circle-fill" *ngIf="firstNameCheck"></i>
          <span class="mx-2">{{'doesnoteincludefname' | translate }}</span>
        </li>
        <li>
          <i class="bi bi-check" *ngIf="!lastNameCheck"></i>
          <i class="bi bi-exclamation-circle-fill" *ngIf="lastNameCheck"></i>
          <span class="mx-2">{{'doesnoteincludelname' | translate }}</span>
        </li>
        <li style="display: none">
          {{'cantbelast4pwd' | translate }}
        </li>
        <li style="display: none">
          {{'cantbelast2hr' | translate }}
        </li>
      </ul>
    </div>
    <label>{{'confirmpassword' | translate }}</label>  <div class="input-group">
      <input
        type="password"
        class="form-control col-md-5 col-xl-5 my-2"
        formControlName="newpassword"
        aria-describedby="button-addon2"
        (keyup)="onConfirmPassword($event)"
        [attr.type]="confirmPasswordVisible ? 'text' : 'password'"
      />
      <i
        (click)="toggleconfirmPasswordVisibile()"
        class="bi bi-eye-slash eye-icon col-md-5 col-xl-5"
        [ngClass]="confirmPasswordVisible ? 'bi bi-eye' : 'bi bi-eye-slash'"
      ></i>
    </div>
    <div class="error-text" *ngIf="mismatch">{{'passwordmismatch' | translate}}</div>
    <div *ngIf="hasErrorInPasswordReset" class="error-message alert alert-danger" role="alert">
      {{ errorArray }}
    </div>
    <div *ngIf="isPasswordResetSuccess" class="sucess-message alert alert-success" role="alert">
      {{ successArray}}
    </div>
   
    <div class="py-2 text-end">
      <button
        class="btn text-end btn-reset"
        (click)="reset($event)"
        type="cancel"
      >
      {{ 'btnreset'  | translate}}
      </button>
      <button class="btn btn-cancel mx-2 my-1" (click)="cancelBtn()"> {{ 'btncancel'  | translate}}</button>

      <button
        class="btn btn-submit mx-2 my-1"

        type="submit"
        [disabled]="
          mismatch ||
          length ||
          !lowerCase ||
          !upperCase ||
          !number ||
          emailCheck ||
          lastNameCheck ||
          firstNameCheck || !resetPasswordForm.valid
        "
      >
      {{ 'btnupdate'  | translate}}
      </button>
    </div>
  </div>
</form>
<!-- Password validation -->
