<ng-container *ngIf="profile | async as user">
  <ng-container>
    <div class="px-3 py-5 profile-card">
      <!-- <div class="back-link">&lt;<a href="https://www.myalcon.com/">Back to [origin/close window]</a></div> -->
      <div class="fw-light fs-1 pt-4 text-center head-title">
        <p>{{ "mnumpersonnelinformation" | translate }}</p>
      </div>
      <div class="fw-bold fs-3 personal-info">
        <p>{{ "mnumpersonnelinformation_subtitle" | translate }}</p>
      </div>
    
      <div class="px-3">
        <div class="card rounded-left rounded-right h-100 my-3">
        
          <div class="row card-group py-2 px-3">
         
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix">
                  <div class="card-body">
                    <h5 class="card-title alcon-text-color fw-bold">
                      {{ "myaccountprofileheader" | translate }}
                    </h5>
                    <div class="">
                      <p class="card-text lead">
                        {{
                          (user.myacc_title===undefined ? "": user.myacc_title + ". " )+
                           
                          (user.first_name===undefined ? "First Name": user.first_name) +
                            " " +
                            (user.last_name===undefined ? "Last Name": user?.last_name)
                        }}
                      </p>
                    </div>
                    <div *ngIf="IsEditProfileEnabled == true">
                      <button
                        type="button"
                        #myButton
                        *ngIf="!isProfileEditMode"
                        class="btn btn-edit py-1 end-float"
                        (click)="loadProfileComponent()"
                      >
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_389_39121)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0112 2.0024L12 2.01357C12.0033 2.01031 12.0065 2.00705 12.0102 2.00333L2.3954 11.6175C2.32652 11.6863 2.28092 11.7752 2.26556 11.8715L1.72804 15.1575C1.70384 15.3055 1.75224 15.4558 1.85835 15.5619C1.96399 15.6676 2.11431 15.7165 2.2623 15.6923L5.54839 15.1547C5.64472 15.1389 5.73361 15.0933 5.80249 15.0244L15.4169 5.41032C16.3569 4.47027 16.3569 2.94338 15.4169 2.00333C14.4805 1.067 12.962 1.06328 12.021 1.99263L12.0112 2.0024ZM12.5319 2.79772L12.6851 2.64508C13.2631 2.08477 14.1873 2.09036 14.7588 2.66137C15.3359 3.23843 15.3359 4.17522 14.7588 4.75229L5.25054 14.2603L2.75096 14.6689L3.15957 12.1699L12.5319 2.79772Z" fill="#003595"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.921875 16.4984C0.921875 16.2775 1.10096 16.0984 1.32188 16.0984H16.5219C16.7428 16.0984 16.9219 16.2775 16.9219 16.4984C16.9219 16.7193 16.7428 16.8984 16.5219 16.8984H1.32188C1.10096 16.8984 0.921875 16.7193 0.921875 16.4984Z" fill="#003595"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_389_39121">
                        <rect width="16" height="16" fill="white" transform="translate(0.921875 0.898438)"/>
                        </clipPath>
                        </defs>
                        </svg>
                         {{ "btnedit" | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="px-3">
                    <router-outlet
                      name="profile"
                      *ngIf="isProfileEditMode"
                    ></router-outlet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded-left rounded-right h-100 my-3">
          <div class="row card-group py-2 px-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix">
                  <div class="card-body">
                    <h5 class="card-title alcon-text-color fw-bold">
                      {{ "myaccountphonenumber" | translate }}
                    </h5>
                    <div class="">
                      <p class="card-text lead" *ngIf="!isPhoneEditMode">
                        {{ user.myacc_phone_no }}
                      </p>
                    </div>
                    <div *ngIf="IsEditProfileEnabled == true">
                      <button
                        type="button"
                        *ngIf="!isPhoneEditMode"
                        class="btn btn-edit py-1 end-float"
                        (click)="loadPhoneNumberComponent()"
                      >
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_389_39103)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0112 2.05318L12 2.06435C12.0033 2.06109 12.0065 2.05784 12.0102 2.05411L2.3954 11.6682C2.32652 11.7371 2.28092 11.826 2.26556 11.9223L1.72804 15.2083C1.70384 15.3563 1.75224 15.5066 1.85835 15.6127C1.96399 15.7184 2.11431 15.7672 2.2623 15.743L5.54839 15.2055C5.64472 15.1897 5.73361 15.1441 5.80249 15.0752L15.4169 5.4611C16.3569 4.52105 16.3569 2.99416 15.4169 2.05411C14.4805 1.11778 12.962 1.11406 12.021 2.04341L12.0112 2.05318ZM12.5319 2.8485L12.6851 2.69586C13.2631 2.13555 14.1873 2.14114 14.7588 2.71215C15.3359 3.28921 15.3359 4.226 14.7588 4.80307L5.25054 14.3111L2.75096 14.7197L3.15957 12.2206L12.5319 2.8485Z" fill="#003595"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.921875 16.5494C0.921875 16.3285 1.10096 16.1494 1.32188 16.1494H16.5219C16.7428 16.1494 16.9219 16.3285 16.9219 16.5494C16.9219 16.7703 16.7428 16.9494 16.5219 16.9494H1.32188C1.10096 16.9494 0.921875 16.7703 0.921875 16.5494Z" fill="#003595"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_389_39103">
                        <rect width="16" height="16" fill="white" transform="translate(0.921875 0.949219)"/>
                        </clipPath>
                        </defs>
                        </svg>
                        
                        <span *ngIf="user.myacc_phone_no">
                          {{ "btnedit" | translate }}</span
                        >
                        <span *ngIf="!user.myacc_phone_no">
                          {{ "btnadd" | translate }}</span
                        >
                      </button>
                    </div>
                  </div>
                  <div>
                    <router-outlet
                      name="phone"
                      *ngIf="isPhoneEditMode"
                    ></router-outlet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded-left rounded-right h-100 my-3">
          <div class="row card-group py-2 px-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix">
                  <div class="card-body">
                    <h5 class="card-title alcon-text-color fw-bold">
                      {{ "myaccountemail" | translate }}
                    </h5>
                    <div class="float-start">
                      <p class="card-text lead">{{ user.email }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded-left rounded-right h-100 my-3">
          <div class="row card-group py-2 px-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix">
                  <div class="card-body">
                    <h5 class="card-title alcon-text-color mt-2 fw-bold">
                      {{ "myaccountpasswordreset" | translate }}
                    </h5>
                    <div class="">*******</div>
                    <div *ngIf="IsEditProfileEnabled == true">
                      <button
                        type="button"
                        *ngIf="!isPasswordResetMode"
                        class="btn btn-edit end-float"
                        (click)="loadResetPasswordComponent()"
                      >
                        <i class="bi bi-arrow-clockwise"></i>
                        {{ "btnreset" | translate }}
                      </button>
                    </div>
                  </div>
                  <div>
                    <router-outlet
                      class="px-3"
                      *ngIf="isPasswordResetMode"
                      name="resetpassword"
                    ></router-outlet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
