import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';


interface Item {
  flag: {
    ss: any,
    nullvalue: any,
    b: any,
    bool: boolean | null,
    ns: any,
    l: any,
    m: any,
    n: any,
    bs: any,
    s: any
  },
  feature: {
    ss: any,
    nullvalue: any,
    b: any,
    bool: boolean | null,
    ns: any,
    l: any,
    m: any,
    n: any,
    bs: any,
    s: string
  }
}

interface FilteredItem {
  flag: boolean
  feature: string
}
@Injectable({
  providedIn: 'root'
})

export class FactorsService {
 
   region:any = sessionStorage.getItem('region') ;
  apiendpoint = environment.oktaGenric.factors.factorAPIendpoint;
 
  private apiFactorsToEnrolledUrl = environment.oktaGenric.factors.factorAPIendpoint+'/factors';
  private apiEnrolledFactorsUrl = environment.oktaGenric.factors.factorAPIendpoint+'/factors/catalog';
  private apiSecretQuestionsUrl = environment.oktaGenric.factors.factorAPIendpoint+'/factors/questions';
  private apiaddQuestionFactorUrl = environment.oktaGenric.factors.factorAPIendpoint+'/factors';
  private apiDeletFactorUrl = environment.oktaGenric.factors.factorAPIendpoint+'/factors';
  private apiVerifyFactorUrl = environment.oktaGenric.factors.factorAPIendpoint+ '/factors/{factorId}/lifecycle/activate';
  constructor(private http: HttpClient, private profileService: ProfileService,@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {
    
 
    
  }

  features: FilteredItem[] = [];

  feature:FilteredItem = {
    feature: '', flag: false
  };



  items: Item[] = [];
  /**
   * Adds the MuleSoft header to the request
   * @returns {HttpHeaders} the headers to be added to the request
   */
  addMuleSoftHeader(): HttpHeaders {
    const accessToken = this.oktaAuth.getAccessToken(); // Get the access token from Okta
    const url =
      environment.mulesoftOktaProfile.jwksUrl + // The MuleSoft Okta JWKS URL
      accessToken + // The access token
      '?clientId=' + // The MuleSoft Okta client id
      environment.mulesoftOktaProfile.mulesoftfactorsapisclientId +
      '&clientSecret=' + // The MuleSoft Okta client secret
      environment.mulesoftOktaProfile.mulesoftfactorsapisclientSecret; // The MuleSoft Okta client secret

    const token = btoa(url); // Base64 encode the URL

    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the content type to JSON
      token: token, // Add the token to the headers
      Authorization: `Bearer ${accessToken}`, // The bearer token for the request
      client_id: environment.mulesoftOktaProfile.mulesoftfactorsapisclientId, // The MuleSoft Okta client id
      client_secret: environment.mulesoftOktaProfile.mulesoftfactorsapisclientSecret // The MuleSoft Okta client secret
    }); // Return the headers

    return headers;
  }

  private factorsToEnrolled: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private enrolledFactors: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private featuresFalags: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  getmyFeatureFlags(): void {
    this.getFeatureFlagsFromDB().subscribe((features) => {
      const result = this.unwrapDynamoDBJson(features);
      const items = result.items;
      const featureFlags = items.reduce((acc: any, item: any) => {
        acc[item.feature.s] = item.flag.bool;
        return acc;
      }, {});
      this.features = featureFlags; // Initialize features array
      this.setFeatureFlags(this.features);
    });
  }


  unwrapDynamoDBJson(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.unwrapDynamoDBJson(item)); // Recursively process arrays
    } else if (obj !== null && typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, value]) => value !== null) // Remove entries with null values
          .map(([key, value]) => [key, this.unwrapDynamoDBJson(value)]) // Recursively process nested objects
      );
    }
    return obj; // Return primitive values as is
  }

  /**
   * Gets the feature flags from the database
   * @returns {Observable<any>} an observable of the feature flags
   */
  getFeatureFlagsFromDB(): Observable<any>  {
    const headers = this.addMuleSoftHeader(); // Adds the MuleSoft header to the request
    const apiUrl = environment.mulesoftOktaProfile.mulesoftapisbaseurl+'/feature'; // The URL of the feature flags API
    return this.http.get(apiUrl, { headers }); // Make the GET request
  }

  /**
   * Sets the feature flags
   * @param {any} value the feature flags
   */
  setFeatureFlags(value: any) {
    /**
     * Sets the feature flags
     */
    this.featuresFalags.next(value);
  }


  /**
   * Gets the feature flags
   * @returns {Observable<any>} an observable of the feature flags
   */
  getFeatureFlags(): Observable<any> {
    /**
     * Gets the feature flags
     * @returns {Observable<any>} an observable of the feature flags
     */
    return this.featuresFalags.asObservable();
  }



  /**
   * Sets the factors to enrolled
   * @param {any} value the factors to enrolled
   */
  setfactorsToEnrolled(value: any) {
    /**
     * Sets the factors to enrolled
     * @param {any} value the factors to enrolled
     */
    this.factorsToEnrolled.next(value);
  }


  /**
   * Gets the factors to enrolled
   * @returns {Observable<any>} an observable of the factors to enrolled
   */
  getfactorsToEnrolled(): Observable<any> {
    /**
     * Gets the factors to enrolled
     * @returns {Observable<any>} an observable of the factors to enrolled
     */
    return this.factorsToEnrolled.asObservable();
  }


  /**
   * Sets the enrolled factors
   * @param {any} value the enrolled factors
   */
  setenrolledFactors(value: any) {
    /**
     * Sets the enrolled factors
     * @param {any} value the enrolled factors
     */
    this.enrolledFactors.next(value);
  }
  /**
   * Gets the enrolled factors
   * @returns {Observable<any>} an observable of the enrolled factors
   */
  getenrolledFactors(): Observable<any> {
    /**
     * Gets the enrolled factors
     * @returns {Observable<any>} an observable of the enrolled factors
     */
    return this.enrolledFactors.asObservable();
  }



  factorstoenroll: any[] = [];
  enrolledfactors: any[] = [];
  myfactors: any[] = [];
  smsFactors: any[] = [];
  qsnFactors: any[] = [];
  public userData: any;
  /**
   * Gets the factors for the current user
   * This function will retrieve the factors to enroll and the enrolled factors
   */
  getMyFactors(): void {    
    /**
     * Gets the factors for the current user
     * This function will retrieve the factors to enroll and the enrolled factors
     */
    this.userData = this.profileService.UserProfile.subscribe((data: any) => {
      /**
       * The user data
       */
      this.userData = data;
      /**
       * The user id
       */
      const userid = data.sub;
      /**
       * Checks if the user id is not undefined
       */
      if (userid !== undefined) {

          this.getFactorsToEnroll(userid)
          /**
           * Subscribe to the observable of the factors to enroll
           * @param {any} factorstoenroll the factors to enroll
           */
          .subscribe((factorstoenroll) => {
            /**
             * Sets the factors to enroll
             * @param {any} factorstoenroll the factors to enroll
             */
            this.factorstoenroll = factorstoenroll;
            /**
             * Sets the factors to enrolled
             * @param {any} factorstoenroll the factors to enroll
             */
            this.setfactorsToEnrolled(factorstoenroll)

          });


          this.getEnrolledFactors(userid)
          /**
           * Subscribe to the observable of the enrolled factors
           * @param {any} enrollefactors the enrolled factors
           */
          .subscribe((enrollefactors) => {
            /**
             * Sets the enrolled factors
             * @param {any} enrollefactors the enrolled factors
             */            
            this.setenrolledFactors(enrollefactors)
          });
      }
    });
  }

  /**
   * Gets the factors to enroll for a user
   * @param {string} userid the user id
   * @returns {Observable<any>} an observable of the factors to enroll
   */
  getFactorsToEnroll(userid:string): Observable<any>  {    
    const headers = this.addMuleSoftHeader();
    const apiUrl = this.apiFactorsToEnrolledUrl;
    return this.http.get(apiUrl, { headers });
  }

  /**
   * Gets the enrolled factors for a user
   * @param {string} userid the user id
   * @returns {Observable<any>} an observable of the enrolled factors
   */
  getEnrolledFactors(userid:string):  Observable<any> {    
    const headers = this.addMuleSoftHeader(); // Adds the MuleSoft header to the request
    const apiUrl = this.apiFactorsToEnrolledUrl; // The URL of the enrolled factors API
    return this.http.get(apiUrl, { headers }); // Make the GET request and return the observable
  }

  /**
   * Verifies a factor for a user
   * @param {string} factorId the factor id
   * @param {string} userid the user id
   * @param {any} body the request body
   * @returns {Observable<any>} an observable of the response
   */
  verifyFactor(factorId: string, userid: string, body: any): Observable<any> {    
    const headers = this.addMuleSoftHeader(); // Adds the MuleSoft header to the request
    const apiUrl = this.apiVerifyFactorUrl.replace('{userid}', userid).replace('{factorId}', factorId).replace('{region}',this.region); // The URL of the verify factor API
    return this.http.post<any>(apiUrl, body, { headers: headers }); // Make the POST request and return the observable
  }


  /**
   * Adds a factor for a user
   * @param {string} userid the user id
   * @param {any} body the request body
   * @returns {Observable<any>} an observable of the response
   */
  addFactor(userid:string,body: any): Observable<any> {    
    const headers = this.addMuleSoftHeader(); // Adds the MuleSoft header to the request
    const apiUrl = this.apiaddQuestionFactorUrl.replace('{userid}', userid).replace('{region}',this.region); // The URL of the add factor API
    return this.http.post<any>(apiUrl, body, { headers: headers }); // Make the POST request and return the observable
  }



  /**
   * Deletes a factor for a user
   * @param {string} factorId the factor id
   * @param {string} userid the user id
   * @returns {Observable<any>} an observable of the response
   */
  deleteFactor(factorId: string, userid: string): Observable<any> {    
    const headers = this.addMuleSoftHeader(); // Adds the MuleSoft header to the request
    const apiUrl = this.apiDeletFactorUrl.replace('{userid}', userid).replace('{region}',this.region); // The URL of the delete factor API
    return this.http.delete<any>(`${apiUrl}/${factorId}`, { headers: headers }); // Make the DELETE request and return the observable
  }



  /**
   * Gets the secret questions for a user
   * @param {string} userid the user id
   * @returns {Observable<any>} an observable of the secret questions
   */
  getSecretQuestions(userid:string): Observable<any> {
    const headers = this.addMuleSoftHeader(); // Adds the MuleSoft header to the request
    const apiUrl = this.apiSecretQuestionsUrl.replace('{userid}', userid).replace('{region}',this.region); // The URL of the secret questions API
    return this.http.get<any>(apiUrl, { headers: headers }); // Make the GET request and return the observable
  }

}
